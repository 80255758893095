/* HEADINGS */

h1, h2, h3, h4, h5, h6 {
    /*font-family: $font-primary;*/
    color: $gray;

    &.underline {
        &::after {
            content: "";
            width: 100px;
            height: 2px;
            background: #a0bd3e;
            display: block;
            margin-top: 18px;
        }

        &.text-white::after {
            content: "";
            background: #fff;
        }
    }
}
// Indicated t = theme 
@each $header, $size in (h1: $h1-t-font-size, 
						 h2: $h2-t-font-size, 
						 h3: $h3-t-font-size, 
						 h4: $h4-t-font-size, 
						 h5: $h5-t-font-size, 
						 h6: $h6-t-font-size) { 
	#{$header} {
	  @include font-size($size);
	}
  }

h1 {

    @include media-breakpoint-up(sm) {

    }

    @include media-breakpoint-up(md) {

    }
}
h2 {

    @include media-breakpoint-up(sm) {
    }

    @include media-breakpoint-up(md) {
    }
}
h3 {

    @include media-breakpoint-up(sm) {
    }
}
h4 {

    @include media-breakpoint-up(sm) {
    }

    @include media-breakpoint-up(sm) {
    }
}




