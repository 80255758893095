/* FORM ELEMENTS */


/* Default Placeholders */

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: inherit;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: inherit;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
}


/* Default Styling overrides */

select::-ms-expand {
    display: none;
}

input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-text-fill-color: $black;
}


/* TYPES OF FORM CONTROLS */

// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]
input,
textarea,
select { 
    @include prefix(appearance, none);
    border: 1px solid;
    line-height: 1;
    padding: 5px;
    outline: 0;
    resize: none;

    &:focus,
    &:active,
    &:hover {
        outline: 0;
    }
    /* Different types of input */
    &[type="text"] {
    }

    &[type="checkbox"] {
    }

    &[type="radio"] {
    }

    &[type="search"] {
    }

    &[type="submit"],
    &[type="button"],
    &[type="reset"] {
    }

    &[type="submit"] {
    }

    &[type="button"] {
    }

    &[type="submit"] {
    }
}


/*Custom checkbox */
