/* HELPERS */

/* background colors */

@each $bg-color, $color in $bg-color {
    .bg-#{$bg-color} {
        background: $color;
    }
}

@each $text-color, $color in $text-color {
    .text-#{$text-color} {
        color: $color;
    }
}

.card {
    border: none! important;
    background:none;
}

//JIRA widget
#jsd-widget{
    bottom: 42px!important;
}

