/* BUTTONS */
button {
    font-family: $font-primary;
    outline: none;

    &:focus, &:hover, &:active {
        outline: none;
    }
}

.btn {
    &:focus, &:hover, &:active, &:active.focus, &:focus.active, &:active:focus {
        outline: none;
    }

    @each $btnName, $btn-font-color, $size, $btn-p, $btn-bor-radius, $btn-bg, $btn-bor, $btn-transition in $default, $primary, $secondary, $tertiary, $quaternary, $xs {

        &.btn-#{$btnName} {
            color: $btn-font-color;
            @include font-size($size);
            padding: $btn-p;
            border-radius: $btn-bor-radius;
            background: $btn-bg;
            border: $btn-bor;
            @include transition($btn-transition);
        }
    }

    @each $btnName, $btn-hover-font-color, $btn-hover-bg, $btn-hover-bor in $defaultHover, $primaryHover, $secondaryHover, $tertiaryHover, $quaternaryHover {

        &.btn-#{$btnName}:hover {
            color: $btn-hover-font-color;
            background: $btn-hover-bg;
            border: $btn-hover-bor;
            text-decoration: $link-decoration;
        }
    }

    &.btn-link {
        border-width: 0 0 1px 0;
    }

    &.element {
        @include rem('padding',19px 0 12px 15px);
    }
}


.btn {
    &.btn-default {
    }

    &.btn-primary {

        &.disabled, &[disabled] {
        }
    }

    &.btn-secondary {
    }

    &.btn-tertiary {
    }

    &.btn-link {
    }
}

.csv-btn{
    font-family: inherit;
    color: #fff;
    &::before{
        font-family: "Font Awesome 5 Free";
        margin-right: 5px; 
    }
    &:hover{
        color: #fff; 
    }
}
