/* Error 404 Page */
.error-page {

	small {
		@include font-size(12px);
		font-weight: lighter;

		@include media-breakpoint-up(xl) {
			@include font-size(10px);
		}
	}
}
