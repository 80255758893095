/* Tooltip */
.Main_wrap__260iu{
    img{
        margin-top: 20px;
    }
}
// Zoom effect cursor overwritten 
.Activated_btn__BDRGy {
    width: 20px !important;
    height: 20px !important;
    left: 96%!important;
    top: 0%!important;
    background: url(../images/ZoomOutIcon.png) no-repeat 6px 6px !important;
    margin-top: 15px !important;
    border: 1px solid #c1c1c1 !important;
    padding: 15px !important;

}
.Main_btn__3CiJa {
    width: 20px !important;
    height: 20px !important;
    left: 94.5% !important;
    top: -8% !important;
    background: url(../images/ZoomInIcon.png) no-repeat  6px 6px !important;
    margin-top: 15px !important;
    border: 1px solid #c1c1c1 !important;
    padding: 15px !important;
}

@media (max-width: 320px){ 
    .Main_btn__3CiJa {
        left: 88.4% !important;
        top: -18% !important;
    }
    .Activated_btn__BDRGy {
        left: 86%!important;
    }
 }
 @media(min-width: 321px) and (max-width: 576px){ 
    .Main_btn__3CiJa {
        left: 90.4% !important;
        top: -14% !important;
    }
    .Activated_btn__BDRGy {
        left: 88%!important;
    }
 }

 @media (min-width: 576px) and (max-width: 991.98px) { 
    .Activated_btn__BDRGy {
        left: 94%!important;
    }
 }