/* Base Stylings like ul, p, body, etc */


/* Body & Html */

body,
html {
    height: 100%;
    @include font-size(16px);
    font-family: $font-primary;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f5f5f5;
}

body {
}


/* Paragraphs */
p {
}


/* Images */

img {
    @include prefix(user-select, none);
}


/* Small */

small {
}


/* Strong */

strong,
b {
    font-weight: $font-weight-bold;
}


/* Links */

a {
    &:hover, &:active, &:focus {
    }
}

sup {
    font-size: 65%;
    top: -0.8em;
}


/* Listings */

ul {
    list-style: none;
}

ul,
ol {
    @include font-size(14px);

    @include media-breakpoint-up(md) {
    }
}

/* .inner-content */
.inner-content {
    p,
    ul li,
    ol li {
    }
}

// Underline text
u {
    text-decoration: none;
    border-bottom: 1px solid;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

// Ellipses
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
