.user-guide{
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 500;
    text-transform: inherit;
    .fa-question-circle{
        font-size: 17px;
    }
}
.mask{
    opacity: 0.2;
}



.reactour__helper{
    max-width: 500px!important;
    top: 13px!important;
    left: 5px!important;;
    .gtkSUb {
        top: 12px!important;
        right: 14px!important;
    }
    .btn-success {
        background-color: #5cb7b7 !important;
    }
    .sc-AxgMl {
        height: 2.2em;
        line-height: 2.3;
    }
}
