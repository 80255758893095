/* Sidebar */
@media (max-width: 991px) {

    .navbar-right{
            position: fixed;
            display: block !important;
            max-width: 280px;
            z-index: 100;
            left: 0;
            
            /* overflow-y: auto; */
            height: 100%;
            &.sidebarOpen{
                padding-left: 0!important;
            }
            &.sidebarHidden{
                display: none !important;
                -webkit-transition: all 150ms ease-in 0s;
                -o-transition: all 150ms ease-in 0s;
                transition: all 150ms ease-in 0s;
            }
        .sidebarNav{
            overflow-y: scroll;
            box-shadow: none;
            ul.firstLevelNav{
                margin-bottom: 3.8rem;
            }
        }
        &:before{
            content: '';
            display: block;
            position: fixed;
            background-color: rgba(0,0,0, 0.50);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
 }

//  @media (min-width: 768px) and (max-width: 991px) { 
//      .navbar-right{
//                 position: fixed;
//             display: block !important;
//             max-width: 85%;
//             z-index: 100;
//             /* overflow-y: auto; */
//             height: 100%;
//             left: 0;
//         .sidebarNav{
//             overflow-y: scroll;
//         }
//     }

// }
.sidebarNav {
    height: 100%;
    padding-bottom: 0;
    background-color: #ececec;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1040;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    will-change: transform;
    backface-visibility: hidden;
    list-style-type: none;
    box-shadow: 0px 0px 10px #ddd;
    .main-page-links{
        ul{
            li{
                a{
                    text-align: left !important;
                    display: block;
                    color: #616161;
                    font-size: .78rem;
                    height: 42px;
                    line-height: 2.3;
                    font-weight: 400;
                    background-color: #f2f2f2;
                    padding-left: 2.5rem;
                    padding-right: 2rem;
                    i{
                        font-size: .78rem;
                        margin-right: 10px;
                    }
                    &:hover, &.active{
                        background-color: #E0E0E0;
                        color: #2A2A2A;
                    }
                }
            }
        }
    }
    ul.firstLevelNav{
        padding: 0;
        margin-bottom: 0;
        li{
            cursor: pointer;
            h5{
                color: #555555;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.7rem;
                padding: 0.7rem;
                margin-bottom: 0;
                border-bottom: 1px solid #CACACA;
                i{
                    color: #222222;
                    margin-right: 10px;
                    -webkit-transition: all 150ms ease-in 0s;
                    -o-transition: all 150ms ease-in 0s;
                    transition: all 150ms ease-in 0s;
                }
            }
            ul{
                padding: 0;
                li{
                    padding: 0;
                    a{
                        display: block;
                        color: #989898;
                        font-size: .78rem;
                        height: 32px;
                        line-height: 2.5;
                        font-weight: 400;
                        background-color: #F7F7F7;
                        padding-left: 2.5rem;
                        padding-right: 2rem;
                        -webkit-tap-highlight-color: transparent;
                        -webkit-transition: all .3s linear;
                        -o-transition: all .3s linear;
                        transition: all .3s linear;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        cursor: pointer;
                        &:hover, &.active{
                            background-color: #E0E0E0;
                            color: #2A2A2A;
                        }
                    }
                }
            }
        }
    }
}
