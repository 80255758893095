/*  ------ Loader Start -----*/
.loader, .globbal-loader{
        .loader-inner{
                z-index: 1;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                }
        }
.globbal-loader{
    z-index: 2000;
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    &.bg-dark{
        background: rgba(0, 0, 0, 0.15)!important;
    }
    .loader-inner{
        top: 50%;
                transform: translateY(-50%);
        }
}
.loader .loader-inner{
        top: 40%;
        transform: translateY(-40%);
        }

