/* VARIABLES */


/* Google Font */
$lato: 'Lato',sans-serif;
$openSans: 'Open Sans',sans-serif;
$ptSans: 'PT Sans',sans-serif;

/*Icon Fonts */
$font-awesome: 'FontAwesome',sans-serif;

//Custom Fonts
//$gordita-black:'Gordita Black';


/* fonts to be used by the entire site */
$font-primary: $lato;
$font-secondary:$openSans;
$font-tertiary :$ptSans;
//$font-quaternary : ;
//$font-quinary : ;

/* Colors */

$black: #000;
$white: #fff;
$gray: #555555;



/* HELPERS */


/* background Colors */
$bg-color: ( gray: $white,);


/* Text Colors */
$text-color: ( white: $white,);


// This variable affects the headings font size classes.
$h1-t-font-size: 36px;
$h2-t-font-size: 32px;
$h3-t-font-size: 28px;
$h4-t-font-size: 20px;
$h5-t-font-size: 13px;
$h6-t-font-size: 12px;


// Fonts

// Font, line-height, and color for body text, headings, and more.
//base font size
$font-size-base:16px;


/* Paragraphs fontsize */
$font-size-para:($font-size-base);



/* Font Weight */
$font-weight-light: 300; /*$light and $book*/
$font-weight-normal: 400; /*$regular*/
$font-weight-semibold: 600; /*$semibold*/
$font-weight-bold: 700; /*$bold*/


// Spacing Margin
// You can add more entries to the $spacers map, should you need more variation.

// Default margin

$margin: () !default;
$margin: map-merge(( /*5:5px*/ ), $margin);

$margin-top: () !default;
$margin-top: map-merge(( 10:10px, 15:15px, 20:20px, 30:30px), $margin-top);

$margin-left: () !default;
$margin-left: map-merge(( /*15:15px*/ ), $margin-left);

$margin-bottom: () !default;
$margin-bottom: map-merge(( 10:10px, 15:15px), $margin-bottom);

$margin-right: () !default;
$margin-right: map-merge(( /*25:25px*/ ), $margin-right);

$margin-x: () !default;
$margin-x: map-merge(( 5:5px), $margin-x);

$margin-y: () !default;
$margin-y: map-merge(( 5:5px), $margin-y);

// Small devices margin (landscape phones, 576px and up)

$margin-sm: () !default;
$margin-sm: map-merge(( /*1:1px*/ ), $margin-sm);

$margin-sm-top: () !default;
$margin-sm-top: map-merge(( 0:0px, 15:15px, 20:20px ), $margin-sm-top);

$margin-sm-left: () !default;
$margin-sm-left: map-merge(( /*3:3px*/ ), $margin-sm-left);

$margin-sm-bottom: () !default;
$margin-sm-bottom: map-merge(( 10:10px ), $margin-sm-bottom);

$margin-sm-right: () !default;
$margin-sm-right: map-merge(( /*14:14px*/ ), $margin-sm-right);

$margin-sm-x: () !default;
$margin-sm-x: map-merge(( 5:5px), $margin-sm-x);

$margin-sm-y: () !default;
$margin-sm-y: map-merge(( 5:5px), $margin-sm-y);

// Medium devices margin  (tablets, 768px and up)

$margin-md: () !default;
$margin-md: map-merge(( ), $margin-md);

$margin-md-top: () !default;
$margin-md-top: map-merge(( 0:0px, 15:15px, 20:20px, 30:30px ), $margin-md-top);

$margin-md-left: () !default;
$margin-md-left: map-merge(( ), $margin-md-left);

$margin-md-bottom: () !default;
$margin-md-bottom: map-merge(( ), $margin-md-bottom);

$margin-md-right: () !default;
$margin-md-right: map-merge(( ), $margin-md-right);

$margin-md-x: () !default;
$margin-md-x: map-merge(( 5:5px), $margin-md-x);

$margin-md-y: () !default;
$margin-md-y: map-merge(( 5:5px), $margin-md-y);

// Large devices margin (desktops, 992px and up)

$margin-lg: () !default;
$margin-lg: map-merge(( ), $margin-lg);

$margin-lg-top: () !default;
$margin-lg-top: map-merge(( ), $margin-lg-top);

$margin-lg-left: () !default;
$margin-lg-left: map-merge(( ), $margin-lg-left);

$margin-lg-bottom: () !default;
$margin-lg-bottom: map-merge(( ), $margin-lg-bottom);

$margin-lg-right: () !default;
$margin-lg-right: map-merge(( ), $margin-lg-right);

$margin-lg-x: () !default;
$margin-lg-x: map-merge((5:5px), $margin-lg-x);

$margin-lg-y: () !default;
$margin-lg-y: map-merge((5:5px), $margin-lg-y);

// Extra large devices margin (large desktops, 1200px and up)

$margin-xl: () !default;
$margin-xl: map-merge(( ), $margin-xl);

$margin-xl-top: () !default;
$margin-xl-top: map-merge(( ), $margin-xl-top);

$margin-xl-left: () !default;
$margin-xl-left: map-merge(( ), $margin-xl-left);

$margin-xl-bottom: () !default;
$margin-xl-bottom: map-merge(( ), $margin-xl-bottom);

$margin-xl-right: () !default;
$margin-xl-right: map-merge(( ), $margin-xl-right);

$margin-xl-x: () !default;
$margin-xl-x: map-merge((5:5px), $margin-xl-x);

$margin-xl-y: () !default;
$margin-xl-y: map-merge((5:5px), $margin-xl-y);

// Spacing Padding
// You can add more entries to the $spacers map, should you need more variation.

// Default $padding

$padding: () !default;
$padding: map-merge(( 0:0px, ), $padding);

$padding-top: () !default;
$padding-top: map-merge(( 15:15px ), $padding-top);

$padding-left: () !default;
$padding-left: map-merge(( 10:10px, ), $padding-left);

$padding-bottom: () !default;
$padding-bottom: map-merge(( 15:15px, ), $padding-bottom);

$padding-right: () !default;
$padding-right: map-merge(( 10:10px ), $padding-right);

$padding-right: () !default;
$padding-right: map-merge(( /*25:25px*/ ), $padding-right);

$padding-x: () !default;
$padding-x: map-merge(( 5:5px), $padding-x);

$padding-y: () !default;
$mapaddingrgin-y: map-merge(( 5:5px), $padding-y);


// Small devices Padding (landscape phones, 576px and up)

$padding-sm: () !default;
$padding-sm: map-merge(( /*1:1px*/ ), $padding-sm);

$padding-sm-top: () !default;
$padding-sm-top: map-merge(( /*2:2px*/ ), $padding-sm-top);

$padding-sm-left: () !default;
$padding-sm-left: map-merge(( 0:0px ), $padding-sm-left);

$padding-sm-bottom: () !default;
$padding-sm-bottom: map-merge(( /*4:4px*/ ), $padding-sm-bottom);

$padding-sm-right: () !default;
$padding-sm-right: map-merge((0:0px ), $padding-sm-right);

$padding-sm-x: () !default;
$padding-sm-x: map-merge(( 5:5px), $padding-sm-x);

$padding-sm-y: () !default;
$padding-sm-y: map-merge(( 5:5px), $padding-sm-y);

// Medium devices Padding  (tablets, 768px and up)

$padding-md: () !default;
$padding-md: map-merge(( /*1:1px*/ ), $padding-md);

$padding-md-top: () !default;
$padding-md-top: map-merge(( /*1:1px*/ ), $padding-md-top);

$padding-md-left: () !default;
$padding-md-left: map-merge(( /*1:1px*/ ), $padding-md-left);

$padding-md-bottom: () !default;
$padding-md-bottom: map-merge(( 0:0px ), $padding-md-bottom);

$padding-md-right: () !default;
$padding-md-right: map-merge(( /*1:1px*/ ), $padding-md-left);

$padding-md-x: () !default;
$padding-md-x: map-merge(( 5:5px), $padding-md-x);

$padding-md-y: () !default;
$padding-md-y: map-merge(( 5:5px), $padding-md-y);

// Large devices Padding (desktops, 992px and up)

$padding-lg: () !default;
$padding-lg: map-merge(( /*1:1px*/ ), $padding-lg);

$padding-lg-top: () !default;
$padding-lg-top: map-merge(( /*1:1px*/ ), $padding-lg-top);

$padding-lg-left: () !default;
$padding-lg-left: map-merge(( /*1:1px*/ ), $padding-lg-left);

$padding-lg-bottom: () !default;
$padding-lg-bottom: map-merge(( /*1:1px*/ ), $padding-lg-bottom);

$padding-lg-right: () !default;
$padding-lg-right: map-merge(( /*1:1px*/ ), $padding-lg-right);

$padding-lg-x: () !default;
$padding-lg-x: map-merge(( 5:5px), $padding-lg-x);

$padding-lg-y: () !default;
$padding-lg-y: map-merge(( 5:5px), $padding-lg-y);

// Extra large devices Padding (large desktops, 1200px and up)

$padding-xl: () !default;
$padding-xl: map-merge(( 1:1px ), $padding-xl);

$padding-xl-top: () !default;
$padding-xl-top: map-merge(( 2:2px ), $padding-xl-top);

$padding-xl-left: () !default;
$padding-xl-left: map-merge(( 3:3px ), $padding-xl-left);

$padding-xl-bottom: () !default;
$padding-xl-bottom: map-merge(( 4:4px ), $padding-xl-bottom);

$padding-xl-right: () !default;
$padding-xl-right: map-merge(( 5:5px ), $padding-xl-right);

$padding-xl-x: () !default;
$padding-xl-x: map-merge(( 5:5px), $padding-xl-x);

$padding-xl-y: () !default;
$padding-xl-y: map-merge(( 5:5px), $padding-xl-y);

// Buttons
//Button transition effect 
$btn-transition: color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;


// Buttons Default
$default: default, // button Name
$white, // font-color
16px, // font-size
15px, // padding
5px, // border-radius
$white, // background
1px solid $white, // border-color
$btn-transition; // button transition

// Buttons default Hover
$defaultHover: default, $white, // btn-hover-font-color
$white, // btn-hover-bg
1px solid $white, //btn-hover-bor
$white; //text-decoration

// Buttons Primary
$primary: primary, $white, // font-color
16px, // font-size
15px, // padding
5px, // border-radius
$white, // background
1px solid $white, // border-color
$btn-transition; // button transition

// Buttons Primary Hover
$primaryHover: primary, $white, // btn-hover-font-color
$white, // btn-hover-bg;
1px solid $white, //btn-hover-bor
$link-decoration; //text-decoration


// Buttons Secondary
$secondary: secondary, $white, // font-color
16px, // font-size
15px, // padding
5px, // border-radius
transparent, // background
2px solid $white, // border-color
$btn-transition; // button transition

// Buttons Secondary Hover
$secondaryHover: secondary, $white, // btn-hover-font-color
$white, // btn-hover-bg
2px solid $white, //btn-hover-bor
$link-decoration; //text-decoration

// Buttons Tertiary
$tertiary: tertiary, $white, // font-color
16px, // font-size
15px, // padding
5px, // border-radius
transparent, // background
2px solid $white, // border-color
$btn-transition; // button transition

// Buttons Tertiary Hover
$tertiaryHover: tertiary, $white, // btn-hover-font-color
$white, // btn-hover-bg
2px solid $white, //btn-hover-bor
$link-decoration; //text-decoration

// Buttons Quaternary
$quaternary: quaternary, $white, // font-color
16px, // font-size
15px, // padding
5px, // border-radius
transparent, // background
2px solid $white, // border-color
$btn-transition; // button transition

// Buttons Quaternary Hover
$quaternaryHover: quaternary, $white, // btn-hover-font-color
$white, // btn-hover-bg
2px solid $white, //btn-hover-bor
$link-decoration; //text-decoration

// Buttons default
$xs: xs,
null,
16px,
23px 22px 14px 13px,
null null,
null;
 

